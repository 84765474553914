import React, { useState, useEffect } from "react";
import CarouselCard from "./CarouselCard";
import Slider from "react-slick";
import SessionPage from "./SessionPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Demo.css";
import "./IndividualDemoPage.css";
import { Tooltip, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LifeChapters = ({ configs, onProgressUpdate, isModalOpen, onModalStateChange }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isClosing, setIsClosing] = useState(false);
  const [currentConfig, setCurrentConfig] = useState(null);
  const sliderRef = React.useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openModal = (config) => {
    setCurrentConfig(config);
    onModalStateChange(true);
    setIsClosing(false);
  };

  const closeModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      onModalStateChange(false);
    }, 300);
  };

  const generateGotoFunction = (config, index) => {
    return () => {
      if (index === activeIndex) {
        openModal(config);
      }
    };
  };

  const items = configs.map((config, index) => ({
    id: index,
    chapterNumber: config.chapterNumber,
    imageUrl: config.imageUrl,
    title: config.chapterTitle,
    buttonText: "Learn More",
    goto: generateGotoFunction(config, index),
  }));

  const settings = {
    centerMode: true,
    infinite: true,
    fade: true,
    cssEase: 'ease-in',
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 700,
    slidesToScroll: 1,
    adaptiveHeight: false,
    beforeChange: (current, next) => {
      setActiveIndex(next);

      // Calculate and update progress when slide changes
      const newProgress = ((next + 1) / items.length) * 100;
      onProgressUpdate(newProgress);
    },
    className: "center-mode-carousel",
    arrows: !isMobile, // Hide arrows on mobile
  };

  return (
    <div className={`life-chapters-container ${isMobile ? 'mobile' : ''}`}>
      <div className={`carousel-container ${isMobile ? 'mobile' : ''}`}>
        <Slider ref={sliderRef} {...settings}>
          {items.map((item, index) => {
            const isCenter = index === activeIndex;
            return (
              <div className={`item ${isCenter ? "center" : "inactive"}`} key={item.id}>
                <CarouselCard
                  chapterNumber={item.chapterNumber}
                  imageUrl={item.imageUrl}
                  title={item.title}
                  isCenter={isCenter}
                  buttonText={item.buttonText}
                  goto={item.goto}
                  isMobile={isMobile}
                />
              </div>
            );
          })}
        </Slider>
      </div>

      {isModalOpen && currentConfig && (
        <div className={`modal-overlay ${isClosing ? "fade-out" : ""}`}>
          <div className={`modal-content ${isMobile ? 'mobile' : ''}`}>
            <Tooltip title="Close this Chapter">
              <IconButton
                className={`modal-close-button ${isMobile ? 'mobile' : ''}`}
                onClick={closeModal}
                sx={{
                  position: "absolute",
                  top: isMobile ? 20 : 20,
                  color: 'white',
                  '&:hover': {
                    color: 'orange',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <SessionPage 
              config={currentConfig} 
              numChapters={configs.length} 
              currentChapter={currentConfig.chapterNumber} 
              profileImageUrl={currentConfig.profileImageUrl} 
              isMobile={isMobile}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default LifeChapters;
