import React, { useState, useRef, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ReactPlayer from 'react-player';
import { 
  TextField, 
  Button, 
  Paper, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  IconButton,
  Snackbar,
  CircularProgress,
  Tooltip,
  Box,
  useMediaQuery,
  useTheme,
  Alert
} from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon, ExpandMore as ExpandMoreIcon, Share as ShareIcon, Send as SendIcon, Cancel as CancelIcon} from '@mui/icons-material';
import { shareSnippet } from './utils/shareSnippet';

function findChapterAndSnippetInfo(chaptersArray, input_ID) {
  for (const chapter of chaptersArray) {
    const matchingSnippet = chapter.chapterSnippets.find(snippet => snippet.ID === input_ID);
    
    if (matchingSnippet) {
      return {
        chapterTitle: chapter.chapterTitle,
        snippetTitle: matchingSnippet.snippet_title,
        snippetUrl: matchingSnippet.url
      };
    }
  }
  
  return null;
}

const createProcessedResults = (data, idMap, configs) => {
  return data.data.map((result) => {
    const snippet_id = idMap[result.video_id];
    let chapterNumber = null;
    let snippetNumber = null;

    if (snippet_id) {
      const parts = snippet_id.split('-');
      if (parts.length === 2) {
        chapterNumber = parts[0];
        snippetNumber = parts[1].replace('.mp4', '');
      }
    }

    const input_ID = chapterNumber + '-' + snippetNumber;
    const { chapterTitle, snippetTitle, snippetUrl } = findChapterAndSnippetInfo(configs, input_ID);

    const moduleMatches = result.modules.map(module => 
      `${module.confidence} confidence ${module.type} match`
    );

    const conversationMetadata = result.metadata.find((item) => item.type === 'conversation');
    const textContext = conversationMetadata ? conversationMetadata.text : null;

    return {
      ...result,
      snippet_id,
      chapterNumber,
      snippetNumber,
      textContext,
      chapterTitle,
      snippetTitle,
      snippetUrl,
      moduleMatches,
    };
  });
};

const getConfidenceColor = (confidence) => {
  switch (confidence.toLowerCase()) {
    case 'high':
      return '#4caf50'; // Green
    case 'medium':
      return '#ff9800'; // Orange
    case 'low':
      return '#f44336'; // Red
    default:
      return '#757575'; // Grey
  }
};

const SearchModule = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const [hasResults, setHasResults] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [strippedUrl, setStrippedUrl] = useState(null);
  const [isSharing, setIsSharing] = useState(false);
  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // "success" or "error"

  const playerRef = useRef(null);

  const indexID = props.indexID;
  const configs = props.configs;
  const idMap = JSON.parse(props.idMap);
  const TWELVE_API_KEY = process.env.REACT_APP_TWELVE_API_KEY;
  const TWELVE_API_KEY_NEW = process.env.REACT_APP_TWELVE_API_KEY_NEW;
  const lifestoryId = props.configs[0].lifestoryId;
  const subjectName = props.configs[0].personName;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const onReady = useCallback(() => {
    if (!isReady && currentVideo) {
      playerRef.current.seekTo(currentVideo.startTime, "seconds");
      const url = currentVideo.url.split('?')[0];
      setStrippedUrl(url);
      setIsReady(true);
    }
  }, [isReady, currentVideo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.twelvelabs.io/v1.2/search', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          'x-api-key': lifestoryId === 'sw-24-2' ? TWELVE_API_KEY : TWELVE_API_KEY_NEW
        },
        body: JSON.stringify({
          search_options: ['visual', 'conversation'],
          adjust_confidence_level: 0.5,
          group_by: 'clip',
          threshold: 'low',
          sort_option: 'score',
          operator: 'or',
          conversation_option: 'semantic',
          page_limit: 6,
          index_id: indexID,
          query: searchTerm,
        })
      });

      if (!response.ok) {
        throw new Error('Search failed');
      }

      const data = await response.json();

      const updatedResults = createProcessedResults(data, idMap, configs);
      console.log('Updated Results:', updatedResults);
      setResults(updatedResults || []);
      setIsExpanded(true);
      setHasResults(true);
    } catch (error) {
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleShareSubmit = async (event) => {
    event.preventDefault();
    try {
      await shareSnippet(email, lifestoryId, currentVideo.url, currentVideo.snippetTitle, subjectName);
      setSnackbarMessage("Snippet shared successfully!");
      setSnackbarSeverity("success");
    } catch (error) {
      setSnackbarMessage("Error sharing snippet: " + error.message);
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      setEmail('');
      setIsSharing(false);
    }
  };

  const handleShareCancel = () => {
    setIsSharing(false);
    setEmail('');
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const toggleResults = () => {
    if (hasResults) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleVideoClick = (result) => {
    setCurrentVideo({
      url: result.snippetUrl,
      startTime: result.start,
      chapterTitle: result.chapterTitle,
      snippetTitle: result.snippetTitle
    });
  };

  const handleCloseVideo = () => {
    setCurrentVideo(null);
    setIsReady(false);
  };

  const handleShareClick = () => {
    setIsSharing(true);
  };

  const warmOrange = '#e6b17e';
  const darkOrange = '#cc9a69';
  const buttonTextColor = '#ffffff';

  return (
    <Box sx={{ position: 'relative', zIndex: 1100 }}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Search this Life Story"
          value={searchTerm}
          onChange={handleChange}
          disabled={isLoading}
          sx={{
            zIndex: 500,
            mr: 1,
            flexGrow: 1,
            '& .MuiOutlinedInput-root': {
              height: '3.5rem',
              width: '15rem',
              backgroundColor: 'rgba(245, 245, 245, 0.95)',
              borderRadius: '50px', // This makes it more pill-like
              '& fieldset': {
                borderColor: warmOrange,
                borderRadius: '50px', // Ensure the border follows the pill shape
              },
              '&:hover fieldset': {
                borderColor: darkOrange,
              },
              '&.Mui-focused fieldset': {
                borderColor: darkOrange,
              },
            },
            '& .MuiInputBase-input': {
              color: '#333',
              padding: '0 20px', // Add some horizontal padding inside the input
            },
          }}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={isLoading}
          sx={{
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(240, 140, 0, 0.8)',
            borderRadius: '50%',
            color: buttonTextColor,
            padding: 0,
            minWidth: '3.8rem', // Set minWidth to ensure it stays circular
            width: '3.8rem',    // Make width and height the same
            height: '3.8rem',
            '&:hover': {
              backgroundColor: 'rgba(255, 170, 80, 0.8)',
            },
            '&:disabled': {
              color: 'rgba(255, 255, 255, 0.7)',
            },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <SearchIcon fontSize="medium" />
          )}
        </Button>
        {hasResults && (
          <Tooltip title={isExpanded ? "Hide Results" : "Show Results"}>
            <IconButton 
              onClick={toggleResults}
              sx={{ 
                ml: 1,
                color: warmOrange,
                '&:hover': {
                  backgroundColor: 'rgba(230, 177, 126, 0.1)',
                },
              }}
            >
              <ExpandMoreIcon sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            </IconButton>
          </Tooltip>
        )}
      </form>

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              paddingTop: '5vh',
              zIndex: 500,
            }}
          >
            <Paper 
              elevation={24}
              sx={{
                width: isMobile ? '65%' : '45%',
                maxWidth: '1200px',
                maxHeight: '90vh',
                overflowY: 'auto',
                padding: isMobile ? 2 : 3,
                position: 'relative',
                backgroundColor: '#f9f3e5',
                borderRadius: '8px',
              }}
            >
              <IconButton
                onClick={() => setIsExpanded(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: darkOrange,
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography 
                variant="h4" 
                sx={{ 
                  mb: 3,
                  mt: 1,
                  color: '#333',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  width: '100%',
                  paddingX: 4,
                  paddingBottom: 2,
                  borderBottom: '2px solid #e6b17e',
                  fontSize: '2rem',
                  fontFamily: 'Roboto, sans-serif',
                }}
              >
                Search Results
              </Typography>
              {results.length > 0 ? (
                <List>
                  {results.map((result, index) => (
                    <ListItem 
                      key={index}
                      button
                      onClick={() => handleVideoClick(result)}
                      sx={{
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        '&:hover': {
                          backgroundColor: 'rgba(230, 177, 126, 0.1)',
                        },
                      }}
                    >
                      <ListItemText 
                        primary={`Chapter ${result.chapterTitle}`}
                        secondary={result.snippetTitle}
                        sx={{
                          color: '#333',
                          width: '100%',
                          mb: 1,
                          '& .MuiListItemText-primary': {
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                          },
                          '& .MuiListItemText-secondary': {
                            fontSize: '1.1rem',
                          },
                        }}
                      />
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, width: '100%', zIndex: 500 }}>
                        {result.moduleMatches.map((match, matchIndex) => {
                          const [confidence] = match.split(' ');
                          return (
                            <Box
                              key={matchIndex}
                              sx={{
                                backgroundColor: '#f5f5f5',
                                color: getConfidenceColor(confidence),
                                padding: '4px 8px',
                                borderRadius: '16px',
                                fontSize: '0.875rem',
                                fontWeight: 500,
                                display: 'inline-flex',
                                alignItems: 'center',
                                border: `1px solid ${getConfidenceColor(confidence)}`,
                                zIndex: 500,
                              }}
                            >
                              {match}
                            </Box>
                          );
                        })}
                      </Box>
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography sx={{ color: '#555' }}>No results found.</Typography>
              )}
            </Paper>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!!currentVideo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1200,
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                backdropFilter: 'blur(5px)',
              }}
              onClick={handleCloseVideo}
            />
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ duration: 0.3 }}
              style={{
                width: '80%',
                maxWidth: '1000px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(10px)',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                padding: '2rem',
                position: 'relative',
                zIndex: 1300,
              }}
            >
              <IconButton
                onClick={handleCloseVideo}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: darkOrange,
                  zIndex: 1400,
                }}
              >
                <CloseIcon />
              </IconButton>
              <ReactPlayer
                ref={playerRef}
                url={currentVideo?.url}
                controls
                playing
                width="100%"
                height="auto"
                onReady={onReady}
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                {!isSharing ? (
                  <Button
                    variant="contained"
                    onClick={handleShareClick}
                    startIcon={<ShareIcon />}
                    fullWidth
                    sx={{
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.025)",
                        backgroundColor: "#ff9100",
                      },
                      backgroundColor: "#ff9100",
                    }}
                  >
                    Share this Moment
                  </Button>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TextField
                      type="email"
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      size="small"
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          height: '2.5rem',
                          backgroundColor: '#fff',
                          '& fieldset': {
                            borderColor: warmOrange,
                          },
                          '&:hover fieldset': {
                            borderColor: darkOrange,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: darkOrange,
                          },
                        },
                      }}
                    />
                    <IconButton
                      color="primary"
                      onClick={handleShareSubmit}
                      sx={{ marginLeft: "8px", color: "#ff9100" }}
                    >
                      <SendIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleShareCancel}
                      sx={{ marginLeft: "8px", color: "#ff9100" }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SearchModule;
