import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const CookieContext = createContext();

export const CookieProvider = ({ children }) => {
  const [cookies, setCookies] = useState({
    keyPairId: null,
    policy: null,
    signature: null
  });

  useEffect(() => {
    const loadCookies = () => {
      const keyPairId = Cookies.get('CloudFront-Key-Pair-Id');
      const policy = Cookies.get('CloudFront-Policy');
      const signature = Cookies.get('CloudFront-Signature');
      setCookies({ keyPairId, policy, signature });
    };

    loadCookies();
    // You could add a listener here to update cookies if they change
  }, []);

  return (
    <CookieContext.Provider value={cookies}>
      {children}
    </CookieContext.Provider>
  );
};

export const useCookies = () => useContext(CookieContext);