import React, { useState, useCallback, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { TextField, Button, IconButton, LinearProgress, Tooltip, Fade } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SendIcon from "@mui/icons-material/Send";
import ShareIcon from "@mui/icons-material/Share";
import CancelIcon from "@mui/icons-material/Cancel";
import "./IndividualDemoPage.css";
import "./Demo.css";
import Chapters from "./Chapters";
import { shareSnippet } from './utils/shareSnippet';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

const SessionPage = (props) => {
  const config = props.config;
  const { chapterSnippets } = config;
  const [currentSnippetIndex, setCurrentSnippetIndex] = useState(0);
  const [playing, setPlaying] = useState(true);
  const [showChapters, setShowChapters] = useState(false);
  const [shareText, setShareText] = useState("");
  const [isSharing, setIsSharing] = useState(false);
  const [fadeClass, setFadeClass] = useState("fade-in");
  const [isLoading, setIsLoading] = useState(false);
  const [shareStatus, setShareStatus] = useState(null);
  const [shareContainerHeight, setShareContainerHeight] = useState('4vh');
  const playerRef = useRef(null);
  const currentSnippet = chapterSnippets[currentSnippetIndex];
  const isMobile = useIsMobile();

  useEffect(() => {
    let timer;
    if (shareStatus) {
      timer = setTimeout(() => {
        setShareStatus(null);
        setIsSharing(false);
      }, 2500);
    }
    return () => clearTimeout(timer);
  }, [shareStatus]);

  const handleSnippetSelect = useCallback((snippetIndex) => {
    setFadeClass("fade-out"); 
    setTimeout(() => {
      setCurrentSnippetIndex(snippetIndex);
      setPlaying(true);
      setFadeClass("fade-in");
    }, 1500);
  }, []);

  const handleVideoEnded = useCallback(() => {
    const nextIndex = currentSnippetIndex + 1;
    if (nextIndex < chapterSnippets.length) {
      setFadeClass("fade-out");
      setTimeout(() => {
        setCurrentSnippetIndex(nextIndex);
        setPlaying(true);
        setFadeClass("fade-in");
      }, 1500);
    } else {
      setPlaying(false);
    }
  }, [currentSnippetIndex, chapterSnippets.length]);

  const toggleChaptersVisibility = () => {
    setShowChapters(!showChapters);
  };

  const handleShareButtonClick = () => {
    setIsSharing(true);
    setShareContainerHeight('5vh');
  };

  const handleTextChange = (event) => {
    setShareText(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const capturedText = shareText;
    setIsLoading(true);

    const lifestoryId = config.lifestoryId;
    const snippetUrl = currentSnippet.url;
    const snippetTitle = currentSnippet.snippet_title;
    const subjectName = config.personName;

    try {
      await shareSnippet(capturedText, lifestoryId, snippetUrl, snippetTitle, subjectName);
      setShareStatus({ type: "success", message: "Snippet shared successfully!" });
    } catch (error) {
      setShareStatus({ type: "error", message: "Error sharing snippet: " + error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`no-overflow-body-container ${isMobile ? 'mobile' : ''}`}>
      <main className={`chapter-main-content ${isMobile ? 'mobile' : ''}`}>
        <div className={`header-container ${isMobile ? 'mobile' : ''}`}>
          {isMobile && (
            <div className="location-date-heading mobile">
              <h1 className={fadeClass}>{currentSnippet.snippet_title}</h1>
              <h2>{config.chapterTitle}</h2>
            </div>
          )}
        </div>
        <div className={`total-media-container ${isMobile ? 'mobile' : ''}`}>
          <div className={`horizontal-media-container ${isMobile ? 'mobile' : ''}`}>
            <div className={`video-and-toggle-container ${isMobile ? 'mobile' : ''}`}>
              <div className={`video-background-container ${isMobile ? 'mobile' : ''}`}>
                <div className={`video-container ${isMobile ? 'mobile' : ''}`}>
                  <ReactPlayer
                    ref={playerRef}
                    className="react-player"
                    playing={playing}
                    url={currentSnippet.url}
                    width="100%"
                    height="100%"
                    controls={true}
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    onEnded={handleVideoEnded}
                  />
                </div>
              </div>
              <button className={`toggle-chapters-button ${isMobile ? 'mobile' : ''}`} onClick={toggleChaptersVisibility}>
                <MenuIcon sx={{ color: "white" }} />
              </button>
            </div>
            <div className={`chapter-and-search ${showChapters ? "visible" : "hidden"} ${isMobile ? 'mobile' : ''}`}>
              <Chapters
                snippets={chapterSnippets}
                currentSnippetIndex={currentSnippetIndex}
                onSnippetSelect={handleSnippetSelect}
                isMobile={isMobile}
              />
              <div className={`share-container ${isMobile ? 'mobile' : ''}`} style={{ marginTop: "16px", height: shareContainerHeight, transition: 'height 0.3s ease-in-out' }}>
                <Fade in={!isSharing} timeout={300}>
                  <div style={{ display: isSharing ? 'none' : 'block', width: '100%' }}>
                    <Button
                      variant="contained"
                      onClick={handleShareButtonClick}
                      startIcon={<ShareIcon />}
                      fullWidth
                      sx={{
                        height: '4vh',
                        transition: "all 0.3s ease-in-out",
                        "&:hover": {
                          backgroundColor: "#e68300",
                        },
                        backgroundColor: "#ff9100",
                      }}
                    >
                      Share this Moment
                    </Button>
                  </div>
                </Fade>
                <Fade in={isSharing} timeout={300}>
                  <div className="share-form" style={{ width: '100%', display: isSharing ? 'block' : 'none' }}>
                    {!isLoading && !shareStatus && (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label="Enter an e-mail address here"
                          variant="filled"
                          fullWidth
                          value={shareText}
                          onChange={handleTextChange}
                          sx={{ 
                            height: '5vh',
                            flexGrow: 1,
                            marginRight: '8px',
                            backgroundColor: "white", 
                            color: "#ff9100",
                            transition: 'all 0.3s ease-in-out',
                          }}
                        />
                        <Tooltip title="Send">
                          <IconButton
                            onClick={handleFormSubmit}
                            sx={{
                              width: '2.5vw',
                              height: '2.5vh',
                              color: "#ff9100",
                              '&:hover': {
                                transform: 'scale(1.25)',
                              },
                              transition: 'all 0.3s ease-in-out',
                            }}
                          >
                            <SendIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton
                            onClick={() => { setShareText(""); setIsSharing(false); }}
                            sx={{
                              width: '2.5vw',
                              height: '2.5vh',
                              marginLeft: '8px',
                              color: "#ff9100",
                              '&:hover': {
                                transform: 'scale(1.25)',
                              },
                              transition: 'all 0.3s ease-in-out',
                            }}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    {isLoading && (
                      <LinearProgress sx={{ width: '100%', height: '56px' }} />
                    )}
                    <Fade in={!!shareStatus} timeout={300}>
                      <div 
                        style={{ 
                          padding: '16px', 
                          borderRadius: '4px', 
                          backgroundColor: shareStatus?.type === 'success' ? '#4caf50' : '#f44336',
                          color: 'white',
                          textAlign: 'center',
                          fontSize: '16px',
                          display: shareStatus ? 'block' : 'none',
                        }}
                      >
                        {shareStatus?.message}
                      </div>
                    </Fade>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
          {!isMobile && (
            <div className="location-date-heading">
              <h1 className={fadeClass}>{currentSnippet.snippet_title}</h1>
              <h2>{config.chapterTitle}</h2>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default SessionPage;
