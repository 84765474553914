/*
createConfigs.js

Takes in a user profile and generates the config files for each of the Session Pages

Elements of Chapter Config:

1. lifestoryid
2. session_number
3. videoUrl
4. chapter_title
5. snippet titles
6. snippet timestamps
*/

export const generateConfigs = (lifestoryData, cookies, KEY_PAIR_ID) => {
      // Initialize an empty array to store the config files
      const configs = [];
      const { chapters, domain, id, personName } = lifestoryData;
      // console.log('in generateConfigs lifestoryData: ', lifestoryData);

      if (!chapters || !domain || !id || !personName) {
            console.error('generateConfigs: Missing required lifestoryData properties');
            return []; // Early return to avoid further errors
          }
    
      // Iterate over the chapters
      chapters.forEach((chapter, index) => {
        // Extract the necessary information from the chapter
        const lifestoryId = id;
        const chapterNumber = index + 1;
        const baseUrl = `${domain}/${lifestoryId}/`;
        const chapterTitle = chapter.title;
        let imageUrl = new URL(baseUrl + 'cover-photos/chapter-' + (index + 1) + '-cover.jpg');
        imageUrl.searchParams.append('Key-Pair-Id', KEY_PAIR_ID);
        imageUrl.searchParams.append('Policy', cookies['CloudFront-Policy']);
        imageUrl.searchParams.append('Signature', cookies['CloudFront-Signature']);
        imageUrl = imageUrl.toString();
        // Create a config object for the current chapter
        const chapterSnippets = chapter.snippets.map(snippet => {
          const snippetUrl = new URL(baseUrl + 'snippets/' + snippet.ID + '.mp4');
          snippetUrl.searchParams.append('Key-Pair-Id', KEY_PAIR_ID);
          snippetUrl.searchParams.append('Policy', cookies['CloudFront-Policy']);
          snippetUrl.searchParams.append('Signature', cookies['CloudFront-Signature']);
          return {
            ...snippet,
            url: snippetUrl.toString(),
          };
        });

        const config = {
          lifestoryId,
          personName,
          chapterNumber,
          baseUrl,
          chapterTitle,
          chapterSnippets,
          cookies,
          KEY_PAIR_ID,
          imageUrl,
        };
        // Add the config object to the array
        configs.push(config);
      });
    
      // Return the array of config files
      return configs;
    };