/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://xyrnwfea4bfgdi32z6vviqyqgq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-pqaqbnifyzcg7nf7bddydvjfey",
    "aws_cognito_identity_pool_id": "us-east-2:2dfeb5f3-01cb-4a19-84f9-cf021632302b",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_fqaFJqqqZ",
    "aws_user_pools_web_client_id": "6dkdv84bf5gpea55djh10vu2ap",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "v2database-staging",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
