import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

// Function to generate HTML email content
function generateEmailHTML(redirectUrl, subjectName) {
  const subject_first_name = subjectName.split(" ")[0];
  
  return `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Shared Snippet</title>
      <style type="text/css">
          @media screen and (max-width: 600px) {
              .email-container { width: 100% !important; }
              .header-content, .main-content { padding: 20px 10px !important; }
              .logo { width: 40px !important; height: 40px !important; }
              .button { 
                  display: block !important; 
                  width: 100% !important; 
                  text-align: center !important; 
                  box-sizing: border-box !important;
                  padding: 15px !important; 
              }
          }

          .button {
              background-color: #ff9800; 
              color: #ffffff; 
              font-size: 18px; 
              font-weight: bold; 
              text-decoration: none; 
              padding: 15px 25px; 
              border-radius: 5px; 
              display: inline-block;
          }
      </style>
  </head>
  <body style="margin: 0; padding: 0; background-color: #fff9c4; font-family: Arial, sans-serif; line-height: 1.6; color: #5d4037;">
      <div class="email-container" style="max-width: 600px; margin: 0 auto;">
          <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="margin: auto;">
              <tr>
                  <td class="header-content" style="background-color: #ffcc80; padding: 20px; text-align: center; border-radius: 8px; position: relative;">
                      <img class="logo" src="https://virgil-public-assets.s3.us-east-2.amazonaws.com/logo-with-text.png" alt="Logo" style="position: absolute; top: 10px; left: 10px; max-width: 20vw; max-height: 20vh; width: auto; height: auto; pointer-events: none;"/>
                  </td>
              </tr>
              <tr>
                  <td class="main-content" style="background-color: #fffde7; padding: 20px; border-radius: 8px; margin-top: 20px;">
                      <p style="font-size: 16px;">Dear Loved One of ${subjectName},</p>
                      
                      <p style="font-size: 16px;">${subject_first_name} recently recorded their life story with <a href="https://www.virgilmemoirs.com" style="color: #ff9800; text-decoration: underline;">Virgil Memoirs</a>, ensuring their legacy will live on for generations. As part of this journey, ${subject_first_name} has chosen to share an excerpt of their story with you.</p>
                      
                      <p style="text-align: center; margin: 30px 0;">
                          <a href="${redirectUrl}" class="button">View this Shared Moment</a>
                      </p>
                      
                      <p style="font-size: 16px;">This snippet from ${subject_first_name}'s Virgil Memoir captures a unique piece of their life's journey. By clicking the button above, you'll be taken to a private, secure section of our website where you can view this personal snippet. We hope it brings you closer to ${subject_first_name}, sparks meaningful conversations, and helps you see your loved one in a new light. </p>
                      
                      <p style="font-size: 16px;">To watch the snippet, simply click the button above and it will redirect you to a private and secure section of our website. We hope this moment brings you joy and a sense of closeness with your loved one!</p>

                      <p style="font-size: 16px;">Best, </p>
                      <p style="font-size: 16px;">Virgil Memoir Team</p>
                    </td>
              </tr>
              <tr>
                  <td style="background-color: #ffcc80; padding: 20px; text-align: center; font-size: 14px; color: #5d4037; border-radius: 8px; margin-top: 20px;">
                      <p>This email was sent from <a href="https://www.virgilmemoirs.com" style="color: #ff9800; text-decoration: underline;">Virgil Memoirs</a> on behalf of the family of ${subjectName} and their family.</p>
                  </td>
              </tr>
          </table>
      </div>
  </body>
  </html>
  `;
}

export const shareSnippet = async (email, lifestoryId, url, snippetTitle, subjectName) => {
  try {
    await getCurrentUser();
    const session = await fetchAuthSession();
    if (!session || !session.tokens || !session.tokens.idToken) {
      throw new Error('No valid session or ID token available');
    }
    const idToken = session.tokens.idToken.toString();

    const response = await fetch('https://njirbga8jj.execute-api.us-east-2.amazonaws.com/dev/signedURL', { 
      method: 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        lifestoryid: lifestoryId,
        snippetUrl: url,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const json_response = await response.json();
    const signedUrl = json_response.signedUrl;
    const baseUrl = 'https://virgilmemoirs.com/snippet-share';
    
    const formattedName = subjectName.replace(/ /g, "_");
    const formattedTitle = snippetTitle.replace(/ /g, "_");
    
    const redirectUrl = `${baseUrl}?videoUrl=${encodeURIComponent(signedUrl)}&subjectName=${formattedName}&snippetTitle=${formattedTitle}`;
    
    console.log('signedUrl:', signedUrl);
    console.log('redirectUrl:', redirectUrl);
    
    // Extracting parameters using URLSearchParams
    const urlParams = new URLSearchParams(redirectUrl.split('?')[1]);
    
    const extractedVideoUrl = decodeURIComponent(urlParams.get('videoUrl'));
    const extractedSubjectName = urlParams.get('subjectName');
    const extractedSnippetTitle = urlParams.get('snippetTitle');
    
    // console.log('Extracted Video URL:', extractedVideoUrl);
    // console.log('Extracted Subject Name:', extractedSubjectName);
    // console.log('Extracted Snippet Title:', extractedSnippetTitle);

    // Generate HTML email content
    const htmlBody = generateEmailHTML(redirectUrl, subjectName);

    const emailResponse = await fetch('https://o8c9vdgfrj.execute-api.us-east-2.amazonaws.com/email-dev/send-snippet', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${idToken}`,
      },
      body: JSON.stringify({
        to: email,
        subject: 'A Special Moment Has Been Shared With You',
        body: htmlBody,
        isHtml: true  // Add this flag to indicate HTML content
      }),
    });

    if (!emailResponse.ok) {
      throw new Error(`Failed to send email: ${emailResponse.status}`);
    }

    const emailResult = await emailResponse.json();
    console.log('Email sent:', emailResult);

    return emailResult;
  } catch (e) {
    console.error('Error: ', e.message);
    throw e;
  }
};
