import React from "react";
import "./CarouselCard.css";

function CarouselCard({
  imageUrl,
  title,
  goto,
}) {
  return (
    <div className="carousel-card" onClick={goto} role="button" tabIndex="0">
      <div className="carousel-card-body">
        <div className="carousel-card-body-text">
            <h3>{title}</h3>
        </div>
        <div className="carousel-card-image">
          <img src={imageUrl} alt={title || "Chapter image"} />
        </div>
      </div>
    </div>
  );
}

export default CarouselCard;
