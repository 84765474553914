import React, { useState, useEffect } from 'react';
import './Chapters.css';
import shareSnippet from './utils/shareSnippet';

const Chapters = ({ snippets, currentSnippetIndex, onSnippetSelect, isMobile }) => {
  const [expandedChapter, setExpandedChapter] = useState(null);

  const chapters = snippets.reduce((acc, snippet, index) => {
    if (!acc[snippet.chapter]) {
      acc[snippet.chapter] = [];
    }
    acc[snippet.chapter].push({ ...snippet, index });
    return acc;
  }, {});

  useEffect(() => {
    // Expand the chapter containing the current snippet
    const currentChapter = Object.keys(chapters).find(chapter => 
      chapters[chapter].some(snippet => snippet.index === currentSnippetIndex)
    );
    setExpandedChapter(currentChapter);
  }, [currentSnippetIndex, chapters]);

  return (
    <div className={`chapter-list ${isMobile ? 'mobile' : ''}`}>
      <h2 className="header" style={{ textAlign: 'center' }}>Key Moments</h2>
      <div className={`chapter-content ${isMobile ? 'mobile' : ''}`}>
        {Object.entries(chapters).map(([chapterTitle, chapterSnippets], chapterIndex) => (
          <div key={chapterIndex} className={`chapter-group ${isMobile ? 'mobile' : ''}`}>
            {chapterSnippets.map((snippet, snippetIndex) => (
              <div 
                key={snippetIndex}
                className={`chapter-container ${currentSnippetIndex === snippet.index ? 'active' : ''} ${isMobile ? 'mobile' : ''}`}
                onClick={() => onSnippetSelect(snippet.index)}
              >
                <div className={`snippet-title ${isMobile ? 'mobile' : ''}`}>{snippet.snippet_title}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chapters;