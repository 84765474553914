import React, { useState, useEffect, useRef } from "react";
import LifeChapters from "./LifeChapters";
import "./Demo.css";
import SearchBar from "./Search";
import virgilLogo from "../images/icons/logo.png";
import galleryBackground from "../images/gallery-background-light.png";
import { Search as searchIcon } from "@mui/icons-material";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const Demo = (props) => {
  const profileImageUrl = props.profileImageUrl;
  const headingRef = useRef(null);
  const personName = props.configs[0].personName;
  const idMap = props.idMap;
  const indexID = props.indexID;

  const [deviceType, setDeviceType] = useState("desktop");
  const [progress, setProgress] = useState(0); // State to track the progress
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width < 464);
      let newDeviceType = "desktop";
      if (width < 1024 && width >= 464) {
        newDeviceType = "tablet";
      } else if (width < 464) {
        newDeviceType = "mobile";
      }
      setDeviceType(newDeviceType);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Add this new effect for the mobile modal
    if (isMobile) {
      const timer = setTimeout(() => {
        setShowMobileModal(true);
      }, 1000);
      return () => clearTimeout(timer);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [deviceType]);

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  // Callback function to update progress from LifeChapters
  const handleProgressUpdate = (newProgress) => {
    setProgress(newProgress);
  };

  // Update this function to log the state change
  const handleModalStateChange = (isOpen) => {
    // console.log("Modal state changed:", isOpen);
    setIsModalOpen(isOpen);
  };

  const handleCloseMobileModal = () => {
    setShowMobileModal(false);
  };

  return (
    <div className="demo-page">
      <main
        className="main-content-demo"
        style={{
          backgroundImage: `
            linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.8) 100%),
            url(${galleryBackground})
          `,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          minHeight: '100vh',
          zIndex: 1,
        }}
      >
        {isMobile ? (
          <MobileHeader 
            profileImageUrl={profileImageUrl}
            personName={personName}
            progress={progress}
            onSearchClick={toggleSearch}
          />
        ) : (
          <div className="header-flex-container">
            <div className='header-section'>
              <img className="virgil-logo" src={virgilLogo} alt="Virgil Logo" />
            </div>
            <div className={`header-and-progressbar ${isModalOpen ? 'shrink' : ''}`}>
              <div className="header-section header-section-profile">
                <img className="profile-photo" src={profileImageUrl} alt="Profile" />
                <h1 className="demo-name">{personName}</h1>
              </div>
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
            <div className="header-section">
              <SearchBar indexID={indexID} configs={props.configs} idMap={idMap} />
            </div>
          </div>
        )}

        {isMobile && isSearchOpen && (
          <FullScreenSearch 
            indexID={indexID}
            configs={props.configs}
            idMap={idMap}
            onClose={toggleSearch}
          />
        )}

        <div className="chapter-holder">
          <LifeChapters 
            profileImageUrl={props.profileImageUrl} 
            configs={props.configs} 
            onProgressUpdate={handleProgressUpdate}
            isModalOpen={isModalOpen}
            onModalStateChange={handleModalStateChange}
          />
        </div>
        <div className="banner" ref={headingRef}>
          <span>This is an early pre-release of our digital memoir.</span>
            <button onClick={props.onSignOut} className="sign-out-button">
            Sign Out
          </button>
        </div>
      </main>
      
      <Modal
        open={showMobileModal}
        onClose={handleCloseMobileModal}
        aria-labelledby="mobile-modal-title"
        aria-describedby="mobile-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
          <Typography id="mobile-modal-title" variant="h6" component="h2" gutterBottom>
            Mobile Experience Notice
          </Typography>
          <Typography id="mobile-modal-description" sx={{ mt: 2 }}>
            Please note, Virgil is best optimized for the desktop experience. While some features are limited on mobile, you are still able to view the contents of your loved one's vault in its entirety and share these moments via email.
          </Typography>
          <Button 
            onClick={handleCloseMobileModal} 
            sx={{ 
              mt: 3,
              backgroundColor: '#ff9100',
              '&:hover': {
                backgroundColor: '#e68300'
              },
              display: 'block',
              margin: '24px auto 0'
            }}
            variant="contained"
          >
            Acknowledge
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

const MobileHeader = ({ profileImageUrl, personName, progress, onSearchClick }) => (
  <div className="mobile-header">
    <div className="mobile-header-content">
      <div className="mobile-profile-section">
        <div className="mobile-profile-photo-container">
          <img className="mobile-profile-photo" src={profileImageUrl} alt="Profile" />
        </div>
        <h1 className="mobile-demo-name">{personName}</h1>
      </div>
      {/* <img 
        className="mobile-search-icon" 
        src={searchIcon} 
        alt="Search" 
        onClick={onSearchClick}
      /> */}
    </div>
    <div className="mobile-progress-bar-container">
      <div className="mobile-progress-bar" style={{ width: `${progress}%` }}></div>
    </div>
  </div>
);

const FullScreenSearch = ({ indexID, configs, idMap, onClose }) => (
  <div className="full-screen-search">
    <button className="close-search" onClick={onClose}>Close</button>
    <SearchBar indexID={indexID} configs={configs} idMap={idMap} />
  </div>
);

export default Demo;

// JCC Oral History of the Building -- B2B or B2Institution
