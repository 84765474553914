import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { CookieProvider } from './CookieContext';
import Demo from "./components/Demo";
import EarlyLife from "./components/EarlyLife";
import SessionPage from "./components/SessionPage";
import ProfessionalWritingCareer from "./components/Individual-demo-pages/professional-writing-career";

import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { fetchUserAttributes, fetchAuthSession, signOut, getCurrentUser } from 'aws-amplify/auth';
import { generateClient } from 'aws-amplify/api';
import Cookies from 'js-cookie';
import awsExports from './aws-exports';

import { getLifeStory, getUserProfile } from './graphql/customqueries';
import { generateConfigs } from './components/utils/generateConfigs';


Amplify.configure(awsExports);

const client = generateClient();

function AuthenticatedApp() {
    const [userID, setUserID] = useState(null);
    const [cookiesLoaded, setCookiesLoaded] = useState(false);
    const [cookies, setCookies] = useState(null);
    const [keyPairID, setKeyPairID] = useState(null);
    // const [error, setError] = useState(null);
    const { user } = useAuthenticator((context) => [context.user]);
    const [lifestoryData, setLifestoryData] = useState(null);
    const [isLoading , setIsLoading] = useState(true);
    const [profileImageUrl, setProfileImageUrl] = useState(null);
    const [lifestoryid, setLifestoryid] = useState(null);

    const fetchUserProfile = useCallback(async () => {
        if (!user) return;
        try {
            const userAttributes = await fetchUserAttributes();
            const userId = userAttributes.sub;
            setUserID(userId);

            const { data } = await client.graphql({
                query: getUserProfile,
                variables: { id: userId },
                authMode: 'userPool',
            });

            if (data.getUserProfile) {
                // console.log('Data for user: ', data.getUserProfile.lifestoryid);
                setLifestoryid(data.getUserProfile.lifestoryid);
            } else {
                console.log('User profile not found');
                // Here you might want to create a new profile if it doesn't exist
            }
        } catch (error) {
            console.log(error);
        }
    }, [user]);

    const fetchSignedCookies = useCallback(async () => {
        try {
            await getCurrentUser();

            const session = await fetchAuthSession();
            if (!session || !session.tokens || !session.tokens.idToken) {
                throw new Error('No valid session or ID token available');
            }

            const idToken = session.tokens.idToken.toString();

            // Log the request details
            // console.log('Fetch request details:', {
            //     url: 'https://njirbga8jj.execute-api.us-east-2.amazonaws.com/dev/signed-cookies',
            //     method: 'GET',
            //     headers: {
            //         Authorization: `Bearer ${idToken}`,
            //         lifestoryid: lifestoryid,
            //     },
            // });

            const response = await fetch('https://njirbga8jj.execute-api.us-east-2.amazonaws.com/dev/signed-cookies', { 
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    lifestoryid: lifestoryid,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const json_response = await response.json();
            const cookies = json_response.cookies;
            const KEY_PAIR_ID = json_response.KEY_PAIR_ID;

            const expires = new Date(new Date().getTime() + 60 * 60 * 1000 * 12); // 1 hour from now

            Cookies.set('CloudFront-Key-Pair-Id', KEY_PAIR_ID);
            Cookies.set('CloudFront-Policy', cookies['CloudFront-Policy'], { secure: true, expires });
            Cookies.set('CloudFront-Signature', cookies['CloudFront-Signature'], { secure: true, expires });

            const baseUrl = `https://d1s0zkeldqwz1x.cloudfront.net/${lifestoryid}/cover-photos/profile-photo.jpg`;
            let imageUrl = new URL(baseUrl);
            imageUrl.searchParams.append('Key-Pair-Id', KEY_PAIR_ID);
            imageUrl.searchParams.append('Policy', cookies['CloudFront-Policy']);
            imageUrl.searchParams.append('Signature', cookies['CloudFront-Signature']);
            imageUrl = imageUrl.toString();
            // console.log('profileImageUrl:', imageUrl); 
            setProfileImageUrl(imageUrl);

            if (cookies) {
                setCookies(cookies);
                setCookiesLoaded(true);
                setKeyPairID(KEY_PAIR_ID);
                // console.log('Policy: ', cookies['CloudFront-Policy']);
                // console.log('Signature: ', cookies['CloudFront-Signature']);
                console.log('Cookies successfully set');
            }

        } catch (e) {
            // setError(e.message);
            console.error('Error: ', e.message);
        }
    }, [lifestoryid]);

    const fetchLifestoryData = useCallback(async () => {
        // console.log('fetchLifeStory called');
        try {
            // console.log('Fetching lifestory data for lifestoryid:', lifestoryid);
            const lifestoryData = await client.graphql({
                query: getLifeStory,
                variables: { id: lifestoryid },
                authMode: 'userPool'
            });

            // console.log('lifestoryData: ', lifestoryData.data.getLifeStory);

            if (lifestoryData.data.getLifeStory) {
                console.log('successful query of lifestoryData');
                setLifestoryData(lifestoryData.data.getLifeStory);
                setIsLoading(false);
            } else {
                console.log('Lifestory data not found');
            }

        } catch (error) {
            console.error('Error fetching lifeStory Data');
            // setError(error.message || "An error occurred while fetching Lifestory Data");
        }
    }, [lifestoryid]);

    useEffect(() => {
        if (user) {
            fetchUserProfile();
            fetchSignedCookies();
            fetchLifestoryData();
        }
    }, [user, fetchUserProfile, fetchSignedCookies, fetchLifestoryData]);

    let numChapters = 0;
    let generatedConfigs = [];



    if (isLoading || !cookiesLoaded) {
        return <div>Loading...</div>;
    }

    if (cookiesLoaded && !isLoading) {
        // console.log('Line 157: cookies:', cookies);
        numChapters = lifestoryData.chapters.length;
        generatedConfigs = generateConfigs(lifestoryData, cookies, keyPairID);
        console.log('Creating Vault ', userID);
        // console.log('Generated Configs:', generatedConfigs);
        // console.log('numChapters:', numChapters);
    }


    return (
        <Router>
            {/* {user && (
                <div>
                    <p>Welcome, {user.username}</p>
                    <button onClick={() => signOut()}>Sign out</button>
                </div>
            )} */}
            <Routes>
                <Route path="/" element={<Demo 
                    onSignOut={() => signOut()}
                    configs = {generatedConfigs} 
                    profileImageUrl = {profileImageUrl}
                    idMap = {lifestoryData.idMap}
                    indexID = {lifestoryData.indexID}
                />} />
                <Route path="/early-life" element={<EarlyLife />} />
                <Route
                    path="/professional-writing-career"
                    element={<ProfessionalWritingCareer />}
                />
                {[...Array(numChapters)].map((_, index) => (
                    <Route
                        key={index}
                        path={`/session-${index + 1}`}
                        element={<SessionPage 
                            profileImageUrl = {profileImageUrl} 
                            config={generatedConfigs[index]} 
                            currentChapter={index + 1}
                            numChapters={numChapters}
                             />}
                    />
                ))}
            </Routes>
        </Router>
    );
}

function App() {
    return (
        <Authenticator loginMechanisms={['username']} hideSignUp={true}>
            <CookieProvider>
                <AuthenticatedApp />
            </CookieProvider>
        </Authenticator>
    );
}

export default App;